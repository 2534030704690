import { useState } from "react";
import { TextInput } from "../../form/TextInput";

import "./CreateInterestPopup.css";
import { InterestService } from "../../../../../services/InterestService";
import { CarDetailsDTO } from "../../../../../models/api/CarDetailsDTO";
import { ICarDetailsProps } from "../CarDetails";
import { ValidatorFunction, useFormControl } from "../../form/Form";

import { useClosePopup, useOpenPopup } from "../../../../../infrastructure/UIContext";
import { FormFieldTextInput } from "../../form/formField/FormFieldTextInput";
import { InterestRegisteredSuccessfully } from "./InterestRegisteredSuccessfully";
import { Spacer } from "../../Spacer";
import { InterestRegisteredError } from "./InterestRegisteredError";


function nameValidator<T>() : ValidatorFunction<T> {
    return (value: T) => {
        if (!value) {
            return "This field is required";
        }
        return null;
    }
  }
  
function emailValidator<T>() : ValidatorFunction<T> {
    return (value: T) => {
        if (!value) {
            return "This field is required";
        }

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value as string)) {
            return "Invalid email format";
        }
        return null;
    }
  }
  
function phoneValidator<T>() : ValidatorFunction<T> {
    return (value: T) => {
        if (!value) {
            return "This field is required";
        }

        const regex = /^\d*$/;

        if (!regex.test(value as string)) {
            return "Invalid phone number format";
        }
        return null;
    }
  }

export function AllValid(...validationResults: boolean[]) {
    return validationResults.every(Boolean);
}


export function CreateInterestPopup(props: ICarDetailsProps) {

    const contactNameFC = useFormControl<string>({validators: [nameValidator()], enableAutoValidate: true});
    const contactEmailFC = useFormControl<string>({validators: [emailValidator()], enableAutoValidate: true});
    const contactPhoneFC = useFormControl<string>({validators: [phoneValidator()], enableAutoValidate: true});
    
    const openPopup = useOpenPopup();
    const closePopup = useClosePopup();

    const handleSubmitBtnClick = () => {
        console.log("Submit button clicked");

        if (!AllValid(
            contactNameFC.validate(), 
            contactEmailFC.validate(), 
            contactPhoneFC.validate())) {
            return;
        }

        const interestService = new InterestService();
        interestService.postInterest({
            carId : props.car?.carId || "",
            contactName: contactNameFC.value || "" ,
            contactEmail: contactEmailFC.value || "",
            contactPhoneNumber: contactPhoneFC.value || "",
            carBrand: props.car?.brand || "",
            carModel: props.car?.model || "",
            carLicensePlate: props.car?.plate || ""
        
        }).then(() => {
            openPopup(<InterestRegisteredSuccessfully/>);
        }).catch((error) => {
            console.log(error);
            openPopup(<InterestRegisteredError message={error.response.data}/>);
        });

    }

    return( 
        <div className="interest-popup">
            <div className="popup-title">
                Register interest
            </div>
            <div className="popup-content">
                Please insert your contact information 
            </div>
            <div className="popup-input-content">
                <FormFieldTextInput formControl={contactNameFC} label="Name" placeholder="Insert name" className="popup-text-input"/>                
            </div>
            <div className="popup-input-content">
                <FormFieldTextInput formControl={contactEmailFC} label="Email" placeholder="Insert email" className="popup-text-input"/>                
            </div>
            <div className="popup-input-content">
                <FormFieldTextInput formControl={contactPhoneFC} label="Phone Number" placeholder="Insert phone number" className="popup-text-input"/>                
            </div>
            <Spacer mode="vertical" px={15}/>
            <div className="popup-btns">
                <div className="btn cancel" onClick={() => closePopup()}>
                    Cancel
                </div>
                <div className="btn submit" onClick={handleSubmitBtnClick}>
                    Submit
                </div>
            </div>
        </div>
    );
}
import "./CarCharacteristic.css"

export interface ICarCharacteristicProps {
    // title: string;
    value: string;
}

export function CarCharacteristic(props: ICarCharacteristicProps) {
    return (
        <div className="car-characteristic-item">
            {/* <div className="car-characteristic-title">
                {props.title}
            </div> */}
            <div className="car-characteristic-value">
                {props.value}
            </div>
        </div>
    );
}

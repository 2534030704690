import { BrandsResponseDTO } from "../models/api/BrandsResponseDTO";
import { ConfigurationProvider } from "../infrastructure/configuration/ConfigurationProvider";
import { ModelResponseDTO } from "../models/api/ModelResponseDTO";
import axios from "axios";

const route = (path: string) => `${ConfigurationProvider.getConfiguration().App.BackendUrl}/${path}`;


export class BrandService {

    public async getBrands(): Promise<BrandsResponseDTO> {
        return axios.request<BrandsResponseDTO>({
            method: "GET",
            url: route("api/Catalog/Brands")
        }).then((response) => {
            return response.data;
        });
    }

    public async getModelBrands(brandId: string): Promise<ModelResponseDTO> {
        return axios.request<ModelResponseDTO>({
            method: "GET",
            url: route(`api/Catalog/Brands/${brandId}/Models`),
        }).then((response) => {
            return response.data;
        });
    }
}



import { useEffect, useMemo, useState } from "react";
import { CarCard, CarSummary } from "../components/catalog/CarCard";
import { CarService } from "../../../services/CarService";
import "./CatalogPage.css";
import { CatalogFilters } from "../components/catalog/CatalogFilters";
import { ReactComponent as BackArrow } from '../../../assets/back_arrow_icon.svg';
import { ReactComponent as FrontArrow } from '../../../assets/front_arrow_icon.svg';
import { totalmem } from "os";
import { FormFieldTextInput } from "../components/form/formField/FormFieldTextInput";
import { ValidatorFunction, useFormControl } from "../components/form/Form";
import { Loader } from "../components/Loader";
import { ConditionalRender } from "../components/ConditionalRender";
var abortController = new AbortController();

function pageLengthValidator<T>() : ValidatorFunction<T> {
  return (value: T) => {
      if (!value) {
          return "This field is required";
      }

      const regex = /^\d*$/;

      if (!regex.test(value as string)) {
          return "Page length must be a number";
      }
      return null;
  }
}

export function CatalogPage() {

    const pageLengthFC = useFormControl<string>({validators: [pageLengthValidator()], enableAutoValidate: true, initialValue:"6"});

    const [page, setPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);

    const [cars, setCars] = useState<CarSummary[]>([]);

    const [filters, setFilters] = useState<CatalogFilters>()

    const [loading, setLoading] = useState(true);

    function handlePaginationBackClick() {
      if (page > 0){
        setPage(page - 1)
      }
    }

    const length = useMemo(()=> Number.parseInt(pageLengthFC.value|| "0")  ,[pageLengthFC.value]);

    function handlePaginationFrontClick() {

      if((page*length + length )< totalItems){
        setPage(page + 1);
      }
    }

    useEffect(() => {
      setPage(0);
    }, [filters, pageLengthFC.value, pageLengthFC.isValid]);
  
    useEffect(() => {

      if (!pageLengthFC.isValid || pageLengthFC.value == "") return;
      
      abortController.abort();
      abortController = new AbortController();

      setLoading(true)
      const carService = new CarService()
      carService.getCarCatalog(
        {
        page: page,
        pageLength: length,
        brandName: filters?.brand,
        modelName: filters?.model,
        minMileage: filters?.minMileage,
        maxMileage: filters?.maxMileage,
        minPrice: filters?.minPrice,
        maxPrice: filters?.maxPrice,
        startYear: filters?.minYear,
        endYear: filters?.maxYear,
        transmissionTypeName: filters?.transmission,
        fuelTypeName: filters?.fuelType,
        bodyStyleName: filters?.bodyStyle,
        driveLineName: filters?.driveLine
        }, abortController)
      .catch((error) =>{
        if(error.code !== "ERR_CANCELED"){ // Ignore aborted requests
          return;
        }
      }) 
      .then((response) => {
        if (response === undefined) return;
        setTotalItems(response.totalItems)
        setCars(response.cars.map((car) => {
          return {
            carId: car.carId || "No id",
            pictureURL: car.pictureURL || "",
            brand: car.brand || "No brand",
            model: car.model || "No model",
            price: car.price || 0,
            mileage: car.mileage || 0,
            fuel: car.fuelType || "No fuel"
          }
        }))
        setLoading(false)
      });
    }, [filters,page, pageLengthFC.value, pageLengthFC.isValid]);
  
    const handleFiltersChanged = (filters : CatalogFilters) =>{
      setFilters(filters)
    }

    return (
        <div className="catalog-page">
          <div className="catalog-header">
             Car Dealership
          </div>
          <div className="catalog-main-content">
            <div className="catalog-filters">
              <CatalogFilters onFiltersChanged={handleFiltersChanged}/>
            </div>
            <div className ="catalog">
              <ConditionalRender if = {loading}>
                <div className="catalog-loader">
                  <Loader/> 
                </div>
              </ConditionalRender>
              <ConditionalRender if = {!loading && cars.length < 1}>
                <div className="catalog-loader">
                  No results found
                </div>
              </ConditionalRender>
              <ConditionalRender if = {!loading && cars.length > 0}>
                <div className="catalog-content">
                  {cars.map((car) => (
                    <CarCard car={car} />
                  )) }
                </div>
              </ConditionalRender>
              <div className="pagination-control">
                <FormFieldTextInput formControl={pageLengthFC} label="Page length:" placeholder="Length" className="page-length-input"/>                                
                Showing {page*length + 1} to {page*length + length} of {totalItems} items
                <div className="back-btn" onClick={handlePaginationBackClick}>
                    <BackArrow/>
                </div>
              
                <div className="page">
                  {page + 1}
                </div>
                <div className="front-btn" onClick={handlePaginationFrontClick}>
                    <FrontArrow/>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
import { createContext, useContext, useState } from "react";



export interface UISContextState {
    showPopup: boolean;
    setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;

    popupContent: React.ReactNode;
    setPopupContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}
  


const UIContext = createContext<UISContextState|null>(null);


export function UIProvider(props: {children: React.ReactNode}) {

    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState<React.ReactNode>(null);

    return (
        <UIContext.Provider value={{showPopup, setShowPopup, popupContent, setPopupContent}}>
            {props.children}
        </UIContext.Provider>
    );

}



/**************************
 * Popup Hooks
 * ***********************/

export function useRenderPopup() {
    const context = useContext(UIContext);

    if(!context) {
        throw new Error("usePopup must be used within a UIProvider");
    }

    return {showPopup: context.showPopup, popupContent: context.popupContent}
}


export function useOpenPopup() {
    const context = useContext(UIContext);

    if(!context) {
        throw new Error("usePopup must be used within a UIProvider");
    }

   
    return (content: React.ReactNode) => {
        context.setPopupContent(content);
        context.setShowPopup(true);
    
    }
}

export function useClosePopup() {
    const context = useContext(UIContext);

    if(!context) {
        throw new Error("usePopup must be used within a UIProvider");
    }

   
    return () => {
        context.setPopupContent(null);
        context.setShowPopup(false);
    
    }
}
import { CssHelper } from "../../../utils/CssHelper";
import "./Spacer.css";

import { useMemo } from "react";

export interface ISpacerProps {
    mode: 'horizontal' | 'vertical'
    px?: string|number;
}

export function Spacer(props: ISpacerProps) {

    const style = useMemo(() => {
        let px = props.px || "16";

        return props.mode === 'horizontal'
            ? {width: CssHelper.pxToRem(px)}
            : {height: CssHelper.pxToRem(px)};

    }, [props.mode, props.px]);

    return (
        <div className="spacer" style={style}></div>
    );
}
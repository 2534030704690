import transmissionSvg from '../../../../assets/transmission.svg'
import doorsSvg from '../../../../assets/doors.svg'
import batterySvg from '../../../../assets/batery.svg'
import seatsSvg from '../../../../assets/seats.svg'
import linedriveSvg from '../../../../assets/line-drive.svg'
import frameSvg from '../../../../assets/frame.svg'
import colorSvg from '../../../../assets/color.svg'

import "./CarDetails.css"	
import { CarDetailsDTO } from '../../../../models/api/CarDetailsDTO'
import { useOpenPopup } from '../../../../infrastructure/UIContext'
import { CreateInterestPopup } from './popups/CreateInterestPopup'
import { InterestRegisteredSuccessfully } from './popups/InterestRegisteredSuccessfully'


export interface ICarDetailsProps {
    car?: CarDetailsDTO;
}

export function CarDetails(props : ICarDetailsProps) {

    const openPopup = useOpenPopup();


    function handleInterestBtnClicked(): void {
        openPopup(<CreateInterestPopup car={props.car}/>)
    }

    return (
        <div className="car-details">
            <div className="car-details-title">
                Details
            </div>
            {props.car?.electricRange != null && <div className="car-details-property">
                <img src={batterySvg} alt="" />
                {props.car.electricRange} km
            </div>}
            <div className="car-details-property transmissionType">
                <img src={transmissionSvg} alt="" />
                {props.car?.transmissionType || "-"}
            </div>
            <div className="car-details-property lineDrive">
                <img src={linedriveSvg} alt="" />
                {props.car?.driveLine || "-"}
            </div>
            <div className="car-details-property bodyStyle">
                <img src={frameSvg} alt="" />
                {props.car?.bodyStyle || "-"}
            </div>
            <div className="car-details-property numberOfDoors">
                <img src={doorsSvg} alt="" />
                {props.car?.numberOfDoors || "-"}
            </div>
            <div className="car-details-property numberOfSeats">
                <img src={seatsSvg} alt="" />
                {props.car?.numberOfSeats || "-"}
            </div>
            <div className="car-details-property color">
                <img src={colorSvg} alt="" />
                {props.car?.color || "-"}
            </div>
            <div className="car-details-spacer">
            </div>
            <div className="car-details-button" onClick={handleInterestBtnClicked}>
                Interested
            </div>
        </div>
    );

}
import './index.css';

import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Startup } from './infrastructure/app/Startup';
import reportWebVitals from './reportWebVitals';

Startup.Run(() => {

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

  
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();

});

import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { CarCard, CarSummary } from './features/car-catalog/components/catalog/CarCard';
import { CarService } from './services/CarService';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CatalogPage } from './features/car-catalog/pages/CatalogPage';
import { CarDetailsPage } from './features/car-catalog/pages/CarDetailsPage';
import { Layout } from './features/Layout';
import { UIProvider } from './infrastructure/UIContext';
import { NotFound } from './features/car-catalog/pages/NotFound';


function App() {

  return (
    <UIProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<CatalogPage />} />
            <Route path="notFound" element={<NotFound />} />
            <Route path=":id" element={<CarDetailsPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </UIProvider>
  );
}

export default App;



import { useClosePopup } from "../../../../../infrastructure/UIContext";
import "./InterestRegisteredSuccessfully.css"

export function InterestRegisteredSuccessfully(){

    const closePopup = useClosePopup();

    return (
        <div className = "success-popup">
            <div className="popup-title">
                Success!
            </div>
            <div className="popup-content">
                Interest registered successfully! Our sellers will contact you soon!
            </div>
            <div className="popup-actions">
                <div className="ok-btn" onClick={()=> closePopup()}>
                    OK
                </div>
            </div>
        </div>
    );
}
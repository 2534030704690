import { useMemo } from 'react';
import { CssClassnameBuilder } from '../../../utils/CssClassnameBuilder';
import './ZImage.css';

import {ReactComponent as NoImage} from '../../../assets/no-image.svg'

interface IImageProps {
    src?: string | null;
    className?: string;
}

export function ZImage(props : IImageProps) {

    const css = useMemo(() => CssClassnameBuilder.new()
    .add("z-image")
    .addConditional(props.className,props.className)
    .build(), [props.className])

    return(
    <div className={css}>
        {props.src ?
            <img src={props.src}/>
            :
            <NoImage/>
        }
    </div>
    )
}
import "./CarDetailsPage.css"

import { CarCard, CarSummary } from "../components/catalog/CarCard";
import { useEffect, useState } from "react";

import { CarCharacteristic } from "../components/carDetails/CarCharacteristic";
import { CarDetails } from "../components/carDetails/CarDetails";
import { CarDetailsDTO } from "../../../models/api/CarDetailsDTO";
import { CarService } from "../../../services/CarService";
import { Spacer } from "../components/Spacer";
import { ZImage } from "../components/ZImage";
import { useNavigate, useParams } from "react-router-dom";

import noImage from '../../../assets/no-image.svg'
import mileageSvg from '../../../assets/mileage.svg'
import yearSvg from '../../../assets/year.svg'
import fuelSvg from '../../../assets/fuel.svg'

import { ReactComponent as BackArrow } from '../../../assets/back_arrow_icon.svg';

export function CarDetailsPage() {

    let {id} = useParams();
    const navigate = useNavigate();

    const [car, setCar] = useState<CarDetailsDTO>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        if (!id) return

        const carService = new CarService()

        carService.getCarDetails(id)
        .then((response) => {
            setCar(response)
            setLoading(false)
        }).catch((error) => {
            if (error.response?.status === 404 || error.response?.status === 400) {
                navigate("/notFound")
            }
        })
    }, []);

  return (
    <div className="car-details-page">
        {loading ? null : <>
        <div className="car-info">
            <div className="car-header">
                <div className="back-btn" onClick={()=>navigate("/")}>
                    <BackArrow/>
                </div>
                <div className="car-title">
                    Car Details
                </div>
            </div>
            <div className="car-main-info">
                <ZImage className="car-highlight" src = {car?.pictureURL}/>
                <Spacer mode="horizontal" px={24}/>
                <div className="car-main-info-card">
                    <div className="car-brand-model-price">
                        <div className = "car-brand-model">
                            <div className="car-brand">
                                {car?.brand}
                            </div>
                            <div className="car-model">
                                {car?.model}
                            </div>
                        </div>
                        <div className = "car-price">
                            {car?.price} $
                        </div>
                    </div>
                    <div className="car-main-details">
                        <div className="car-details-property year">
                            <img src={yearSvg} alt="" />
                            {car?.year || "-"}
                        </div>
                        <div className="car-details-property mileage">
                            <img src={mileageSvg} alt="" />
                            {car?.mileage || "0"} km
                        </div>
                        <div className="car-details-property fuelType">
                            <img src={fuelSvg} alt="" />
                            {car?.fuelType || "-"}
                        </div>
                    </div>
                </div>
            </div>
            <div className="car-characteristics">
                <div className="car-characteristics-header">
                    Characteristics
                </div>
                {car && groupBy(car.characteristics, c => c.characteristicType || "-").map(group => (
                    <>
                        <div className="car-characteristics-group">
                            {group.at(0)?.characteristicType || "-"}
                        </div>
                        <div className="car-characteristics-content">
                            {group.map(c => <CarCharacteristic value={c.characteristicDescription || "-"} />)}
                        </div>
                    </>
                )) }
            </div>
        </div>
        <div className="car-description">
            <CarDetails car={car} />
        </div>
        </>}
    </div>
  );
}

function groupBy<T>(list: T[], keyGetter: (input: T) => string) : T[][] {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return Array.from(map.values());
}

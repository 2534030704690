import { useClosePopup } from "../../../../../infrastructure/UIContext";
import './InterestRegisteredError.css'

export interface InterestRegisteredErrorProps {
    message: string;
}


export function InterestRegisteredError(props: InterestRegisteredErrorProps){

    const closePopup = useClosePopup();

    return (
        <div className = "error-popup">
            <div className="popup-title">
                ERROR
            </div>
            <div className="popup-content">
                Something went wrong. If the following message doesn't help, please try again later.
                <br/>
                {props.message}
            </div>
            <div className="popup-actions">
                <div className="ok-btn" onClick={()=> closePopup()}>
                    OK
                </div>
            </div>
        </div>
    );
}
import { Outlet } from "react-router-dom";

import "./Layout.css"
import { useState } from "react";
import { useClosePopup, useRenderPopup } from "../infrastructure/UIContext";

export function Layout() {

    const {showPopup, popupContent} = useRenderPopup();

    return (
        <div className="app-content">
            <Outlet />
            {showPopup? 
                <PopupArea>
                    {popupContent}
                </PopupArea>
            :null}
        </div>
    );
}

interface IPopupAreaProps {
    children: React.ReactNode;
}

export function PopupArea(props: IPopupAreaProps){
    return (
        <div className="app-overlay">
                <div className="popup">
                    {props.children}
                </div>
        </div>
    );
}
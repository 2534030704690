import "./CarCard.css"
import fuelSvg from '../../../../assets/fuel.svg'
import mileageSvg from '../../../../assets/mileage.svg'
import noImage from '../../../../assets/no-image.svg'
import { useNavigate } from "react-router-dom";
import { ZImage } from "../ZImage";

export interface CarSummary {
	carId: string;
	pictureURL: string;
	brand: string;
	model: string;
	price: number;
	mileage: number;
	fuel: string;
}

export interface ICarCardProps{
	car: CarSummary;
}

export function CarCard(props: ICarCardProps) {
	const navigate = useNavigate();


	const handleCarDetailsClick = () => {
		navigate(`/${props.car.carId}`)
	}

	return (
		<div className="car-card" onClick={() => handleCarDetailsClick()}>
			
			<ZImage className="car-image" src = {props.car?.pictureURL}/>
			
			<div className="car-description">
				<div className="car-brand-model">
					<div className="car-brand">
						{props.car.brand}
					</div>
					<div className="car-model">
						{props.car.model}
					</div>
				</div>
				<div className="car-price">
					{props.car.price}$
				</div>        
			</div>
			<div className="car-characteristics">
				<div className = "car-characteristics-item mileage">
					<img src={mileageSvg} alt="" />
					{props.car.mileage}km
				</div>
				<div className = "car-characteristics-item fuelType">
					<img src={fuelSvg} alt="" />
					{props.car.fuel}
				</div>
			</div>
		</div>
	);
}


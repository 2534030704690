import './NotFound.css';
import {ReactComponent as CarInShopSVG} from '../../../assets/car_in_shop.svg';
import { useNavigate } from 'react-router-dom';

export function NotFound() {
    const navigate = useNavigate();


  return (
    <div className="not-found-page">
        <div className="not-found-content">
            <div className="not-found-header">
                <h1>
                    404 - Not Found!
                </h1> 
                <CarInShopSVG/>
            </div>
            <div className="not-found-actions">
                <div className="return-btn" onClick={() => navigate("/")}>
                    Go to home page
                </div>
            </div>
        </div>
    </div>
  );
}
import { useEffect, useState } from "react";
import "./CatalogFilters.css";
import { BrandSummaryDTO } from "../../../../models/api/BrandSummaryDTO";
import { ModelSummaryDTO } from "../../../../models/api/ModelSummaryDTO";
import { BrandService } from "../../../../services/BrandService";
import { Checkbox } from "../form/Checkbox";
import { TextInput } from "../form/TextInput";


export interface CatalogFilters {
    brand: string[];
    model: string[];
    minYear?: number;
    maxYear?: number;
    minPrice?: number;
    maxPrice?: number;
    minMileage?: number;
    maxMileage?: number;
    transmission: string[];
    fuelType: string[];
    bodyStyle: string[];
    driveLine: string[];
}

interface BrandItem{
    brand: BrandSummaryDTO
    isChecked: boolean,
    children?: ModelItem[]
}

interface ModelItem{
    model: ModelSummaryDTO
    isChecked: boolean
}

interface ICatalogFiltersProps {
    onFiltersChanged: (filters: CatalogFilters) => void;
}

export function CatalogFilters(props :ICatalogFiltersProps){

    // Brand & models
    const [brands,setBrands] = useState<BrandItem[]>([])



    // Year
    const [minYear,setMinYear] = useState<number>()
    const [maxYear,setMaxYear] = useState<number>()

    // Price
    const [minPrice,setMinPrice] = useState<number>()
    const [maxPrice,setMaxPrice] = useState<number>()

    // Mileage
    const [minMileage,setMinMileage] = useState<number>()
    const [maxMileage,setMaxMileage] = useState<number>()

    // Transmission type
    const [automatic,setAutomatic] = useState(false)
    const [manual,setManual] = useState(false)

    // FuelType
    const [diesel,setDiesel] = useState(false)
    const [electric,setElectric] = useState(false)
    const [gasoline,setGasoline] = useState(false)
    const [lpg,setLpg] = useState(false)
    const [hybridDiesel,setHybridDiesel] = useState(false)
    const [hybridGasoline,setHybridGasoline] = useState(false)
    const [hydrogen,setHydrogen] = useState(false)

    // BodyStyle
    const [cabriolet,setCabriolet] = useState(false)
    const [van,setVan] = useState(false)
    const [coupe,setCoupe] = useState(false)
    const [hatchback,setHatchback] = useState(false)
    const [pickUp,setPickUp] = useState(false)
    const [sedan,setSedan] = useState(false)
    const [suv,setSuv] = useState(false)
    const [truck,setTruck] = useState(false)
    const [micro,setMicro] = useState(false)
    const [offRoader,setOffRoader] = useState(false)
    const [sport,setSport] = useState(false)

    // DriveLine
    const [frontWheelDrive,setFrontWheelDrive] = useState(false)
    const [rearWheelDrive,setRearWheelDrive] = useState(false)
    const [fourWheelDrive,setFourWheelDrive] = useState(false)

    useEffect(() => {
        const brandService = new BrandService()
        brandService.getBrands().then((response) => {
            setBrands(response.brands.map((brand) => {
                return {
                    brand: brand,
                    isChecked: false
                }
            }))
        }).catch((error) => {
            //TODO handle error console.error(error)
        })
    },[] )


    // UseEffect to update the filters
    useEffect(() => {
        const filters: CatalogFilters = {
            brand: [],
            model: [],
            minYear: minYear,
            maxYear: maxYear,
            minPrice: minPrice,
            maxPrice: maxPrice,
            minMileage: minMileage,
            maxMileage: maxMileage,
            transmission: [],
            fuelType: [],
            bodyStyle: [],
            driveLine: []
        }

        brands.filter((brandItem) => brandItem.isChecked)
            .forEach((brandItem) => {
                let modelsCount = brandItem.children?.filter((modelItem) => modelItem.isChecked).length
                // If > 0 = true 
                if(modelsCount){
                    brandItem.children?.filter((modelItem) => modelItem.isChecked).forEach((modelItem) => {
                        filters.model.push(modelItem.model.name || "-")
                    })
                }else{
                    filters.brand.push(brandItem.brand.name || "-")
                }
            })

        // Year
        filters.minYear = minYear
        filters.maxYear = maxYear

        // Price
        filters.minPrice = minPrice
        filters.maxPrice = maxPrice

        // Mileage
        filters.minMileage = minMileage
        filters.maxMileage = maxMileage

        // Transmission type
        if(automatic) filters.transmission.push("Automatic")
        if(manual) filters.transmission.push("Manual")

        // FuelType
        if(diesel) filters.fuelType.push("Diesel")
        if(electric) filters.fuelType.push("Electric")
        if(gasoline) filters.fuelType.push("Gasoline")
        if(lpg) filters.fuelType.push("LPG")
        if(hybridDiesel) filters.fuelType.push("Hybrid Diesel")
        if(hybridGasoline) filters.fuelType.push("Hybrid Gasoline")
        if(hydrogen) filters.fuelType.push("Hydrogen")

        // BodyStyle
        if (cabriolet) filters.bodyStyle.push("Cabriolet")
        if (van) filters.bodyStyle.push("Van")
        if (coupe) filters.bodyStyle.push("Coupe")
        if (hatchback) filters.bodyStyle.push("Hatchback")
        if (pickUp) filters.bodyStyle.push("Pick-Up")
        if (sedan) filters.bodyStyle.push("Sedan")
        if (suv) filters.bodyStyle.push("SUV")
        if (truck) filters.bodyStyle.push("Truck")
        if (micro) filters.bodyStyle.push("Micro")
        if (offRoader) filters.bodyStyle.push("Off-Roader")
        if (sport) filters.bodyStyle.push("Sport")
        

        // DriveLine
        if (frontWheelDrive) filters.driveLine.push("Front Wheel Drive")
        if (rearWheelDrive) filters.driveLine.push("Rear Wheel Drive")
        if (fourWheelDrive) filters.driveLine.push("Four Wheel Drive")


        props.onFiltersChanged(filters)
    },[brands,
        minYear,maxYear,
        minPrice,maxPrice,
        minMileage,maxMileage,
        automatic,manual,
        diesel,electric,gasoline,lpg,hybridDiesel,hybridGasoline,hydrogen,
        cabriolet,van,coupe,hatchback,pickUp,sedan,suv,truck,micro,offRoader,sport,
        frontWheelDrive,rearWheelDrive,fourWheelDrive])

    const handleBrandItemChanged= (brandItem: BrandItem, isChecked: boolean) => {
        brandItem.isChecked = isChecked
        if (brandItem.isChecked) {
            brandItem.children = brandItem.brand.models?.map((model) => {
                return {
                    model: model,
                    isChecked: false
                }
            }
        )
        }else{
            brandItem.children = []
        }
        
        setBrands([...brands])

    }

    function handelModelItemChanged(modelItem: ModelItem, isChecked: boolean) {
        modelItem.isChecked = isChecked
        setBrands([...brands])  
    }

  return (
    <div className="filters">
        <div className="filters-title">
            Brand
        </div>
        <div className="filters-content">
            {brands.map((brandItem) => (
                <>
                    <Checkbox 
                        text={brandItem.brand.name || "-"} 
                        isChecked={brandItem.isChecked}
                        dataTest={`filter-${brandItem.brand.name?.toLocaleLowerCase()}`}
                        onClick={(isChecked : boolean) => {handleBrandItemChanged(brandItem,isChecked)}}
                    />
                    <div className="filter-content filter-content-sub">
                        {brandItem.children?.map((modelItem) => (
                            <Checkbox 
                                text={modelItem.model.name || "-"} 
                                dataTest={`filter-${brandItem.brand.name?.toLocaleLowerCase()}-${modelItem.model.name?.toLocaleLowerCase()}`}
                                isChecked={modelItem.isChecked} 
                                onClick={(isChecked : boolean) => {handelModelItemChanged(modelItem,isChecked)}}
                            />
                        ))}
                    </div>
                </>
            ))}
        </div>
        <div className="filters-separator">
        </div>
        <div className="filters-title">
            Price
        </div>
        <div className="filters-content with-inputs">
            <div className="filters-content-input">
                <TextInput value={minPrice} onChange={setMinPrice} placeholder="Min P"/>
            </div>
            <div className="filters-content-input">
                <TextInput value={maxPrice} onChange={setMaxPrice} placeholder="Max P"/>
            </div>
        </div>
        <div className="filters-separator">
        </div>
        <div className="filters-title">
            Mileage
        </div>
        <div className="filters-content with-inputs">
            <div className="filters-content-input">
                <TextInput value={minMileage} onChange={setMinMileage} placeholder="Min M"/>
            </div>
            <div className="filters-content-input">
                <TextInput value={maxMileage} onChange={setMaxMileage} placeholder="Max M"/>
            </div>
        </div>
        <div className="filters-separator">
        </div>
        <div className="filters-title">
            Year
        </div>
        <div className="filters-content with-inputs">
            <div className="filters-content-input">
                <TextInput value={minYear} onChange={setMinYear} placeholder="Min Y"/>
            </div>
            <div className="filters-content-input">
                <TextInput value={maxYear} onChange={setMaxYear} placeholder="Max Y"/>
            </div>
        </div>
        <div className="filters-separator">
        </div>
        <div className="filters-title">
            Transmission
        </div>
        <div className="filters-content">
            <Checkbox text="Automatic" isChecked={automatic} onClick={setAutomatic}/>
            <Checkbox text="Manual" isChecked={manual} onClick={setManual}/>
        </div>
        <div className="filters-separator">
        </div>
        <div className="filters-title">
            Fuel Type
        </div>
        <div className="filters-content">
            <Checkbox text="Diesel" isChecked={diesel} onClick={setDiesel}/>
            <Checkbox text="Electric" isChecked={electric} onClick={setElectric}/>
            <Checkbox text="Gasoline" isChecked={gasoline} onClick={setGasoline}/>
            <Checkbox text="LPG" isChecked={lpg} onClick={setLpg}/>
            <Checkbox text="Hybrid Diesel" isChecked={hybridDiesel} onClick={setHybridDiesel}/>
            <Checkbox text="Hybrid Gasoline" isChecked={hybridGasoline} onClick={setHybridGasoline}/>
            <Checkbox text="Hydrogen" isChecked={hydrogen} onClick={setHydrogen}/>
        </div>
        <div className="filters-separator">
        </div>
        <div className="filters-title">
            Body Type
        </div>
        <div className="filters-content">
            <Checkbox text="Cabriolet" isChecked={cabriolet} onClick={setCabriolet}/>
            <Checkbox text="Van" isChecked={van} onClick={setVan}/>
            <Checkbox text="Coupe" isChecked={coupe} onClick={setCoupe}/>
            <Checkbox text="Hatchback" isChecked={hatchback} onClick={setHatchback}/>
            <Checkbox text="Pick Up" isChecked={pickUp} onClick={setPickUp}/>
            <Checkbox text="Sedan" isChecked={sedan} onClick={setSedan}/>
            <Checkbox text="SUV" isChecked={suv} onClick={setSuv}/>
            <Checkbox text="Truck" isChecked={truck} onClick={setTruck}/>
            <Checkbox text="Micro" isChecked={micro} onClick={setMicro}/>
            <Checkbox text="Off Roader" isChecked={offRoader} onClick={setOffRoader}/>
            <Checkbox text="Sport" isChecked={sport} onClick={setSport}/>
        </div>
        <div className="filters-separator">
        </div>
        <div className="filters-title">
            Drive Line
        </div>
        <div className="filters-content">
            <Checkbox text="Front wheel drive" isChecked={frontWheelDrive} onClick={setFrontWheelDrive}/>
            <Checkbox text="Rear wheel drive" isChecked={rearWheelDrive} onClick={setRearWheelDrive}/>
            <Checkbox text="Four wheel drive" isChecked={fourWheelDrive} onClick={setFourWheelDrive}/>
        </div>
        <div className="filters-separator">
        </div>

    </div>
    )  
}
import { ConfigurationProvider } from "../infrastructure/configuration/ConfigurationProvider";
import { InterestRequestDTO } from "../models/api/InterestRequestDTO";
import axios from "axios";

const route = (path: string) => `${ConfigurationProvider.getConfiguration().App.BackendUrl}/${path}`;



export class InterestService {

    public async postInterest(options?: InterestRequestDTO): Promise<string> {
        return axios.request<string>({
            method: "POST",
            url: route("api/Interests"),
            data: options,
        }).then((response) => {
            return response.data;
        });
    }
}



import { CarDetailsDTO } from "../models/api/CarDetailsDTO";
import { CatalogRequestDTO } from "../models/api/CatalogRequestDTO";
import { CatalogResponseDTO } from "../models/api/CatalogResponseDTO";
import { ConfigurationProvider } from "../infrastructure/configuration/ConfigurationProvider";
import axios from "axios";

const route = (path: string) => `${ConfigurationProvider.getConfiguration().App.BackendUrl}/${path}`;



export class CarService {

    public async getCarCatalog(options?: CatalogRequestDTO, abortController?: AbortController): Promise<CatalogResponseDTO> {
        return axios.request<CatalogResponseDTO>({
            method: "GET",
            url: route("api/Catalog/car"),
            params: options,
            paramsSerializer:{indexes: null},
            signal: abortController?.signal
        }).then((response) => {
            return response.data;
        });
    }

    public async getCarDetails(id: string): Promise<CarDetailsDTO> {
        return axios.request<CarDetailsDTO>({
            method: "GET",
            url: route(`api/Catalog/car/${id}`),
        }).then((response) => {
            return response.data;
        });
    }
}


